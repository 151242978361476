import React, { useEffect } from "react"

import { styled } from "@mui/material/styles"

const AutoSizeIframe = ({ src, title }: { src: string; title: string }) => {
    useEffect(() => {
        const handleResizeEvent = (event: MessageEvent<HTMLIFrameElement>) => {
            if (
                event.origin === process.env.NEXT_PUBLIC_HOST ||
                event.origin === process.env.NEXT_PUBLIC_CALCULATORS_URL
            ) {
                const { height } = event.data
                const bufferHeight = 34 // paddingTop + paddingBottom + borderTop + borderBottom
                if (height) {
                    const iframe = document.getElementById("my-iframe")
                    if (iframe)
                        iframe.style.height = `${height + bufferHeight}px`
                }
            }
        }
        window.addEventListener("message", handleResizeEvent)
        return () => {
            window.removeEventListener("message", handleResizeEvent)
        }
    }, [])

    return (
        <StyledIframe
            id="my-iframe"
            src={src}
            title={title}
        />
    )
}

const StyledIframe = styled("iframe")(({ theme }) => ({
    width: "100%",
    border: "none",
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
}))

export default AutoSizeIframe
