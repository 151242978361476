import { PortableTextTypeComponentProps } from "@portabletext/react"

import { CalculatorBaseProps } from "@nhi/calculators/shared"

import AutoSizeIframe from "Components/PortableText/Serializers/Calculators/AutosizeIframe"

type CalculatorProps = CalculatorBaseProps & {
    id: string
}

type CalculatorSerializerProps = PortableTextTypeComponentProps<CalculatorProps>

//uncomment when new calcs are approved for use , and remove this list when all calculators are migrated

// const newCalculators = [
//     { id: "Barthel", oldId: "barthel" },
//     { id: "BodyMassIndex", oldId: "bodymassindex" },
//     { id: "DrugCalculatorDriversLicense", oldId: "drugsdriverlicence" },
//     { id: "CHA2DS2-VASc", oldId: "cha2ds2vasc" },
//     { id: "WaistHipIndex", oldId: "waisthipindex" },
//     { id: "IPSS", oldId: "ipss" },
//     { id: "NORRISK2", oldId: "norrisk2" },
//     { id: "Pefnomogram", oldId: "pefnomogram" },
//     { id: "Sukk-s", oldId: "sukks" }
// ]

//if the calculator is in the new calculators list, return the new calculator id
// const useNewCalculator = (id: string) => {
//     let calculatorId: undefined | string
//     newCalculators.map(calc => {
//         if (calc.oldId === id) calculatorId = calc.id
//     })
//     return calculatorId
// }

export function CalculatorIframe(props: CalculatorProps) {
    //when the new calculators are approved for use, remove this
    const calculatorId = undefined
    //and uncomment this
    // const calculatorId = useNewCalculator(props.id)

    //remove this if-condition when all calculators are migrated
    if (calculatorId !== undefined)
        return (
            <AutoSizeIframe
                src={`${process.env.NEXT_PUBLIC_CALCULATORS_URL}/${calculatorId}`}
                title={calculatorId}
            />
        )
    else if (props.id)
        return (
            <AutoSizeIframe
                src={`/calculators/${props.id}`}
                title={props.id}
            />
        )
    else return null
}

export default function CalculatorIframeSerializer(
    props: CalculatorSerializerProps
) {
    return (
        <CalculatorIframe
            id={props.value.id}
            {...props}
        />
    )
}
