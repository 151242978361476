/**
 * Get value of key in query string
 * @param key key to get from query string
 * @returns value of key in query string
 */
export default function getQueryStringValue(key: string) {
    if (typeof window !== "undefined") {
        const searchParams = new URLSearchParams(window.location.search)
        return searchParams.get(key)
    }
    return null
}
