import { styled } from "@mui/material/styles"

import usePagingContext from "Components/Layout/PageComponent/PagingProvider"

import Ad from "./Ad"

export type AdInstreamType =
    | "artikkel_instream1" // article: første reklame
    | "artikkel_instream2" // article: andre reklame

interface IAdInstreamProps {
    type: AdInstreamType
    usage: string
}

export default function AdInstream({ type, usage }: IAdInstreamProps) {
    const { paginate, pageParam } = usePagingContext()

    if (!showAd(paginate, pageParam, usage)) return null

    return (
        <AdWrapper>
            <Ad type={type} collapseEmpty={""} />
        </AdWrapper>
    )
}

const AdWrapper = styled("div")(({ theme }) => ({
    marginBottom: theme.spacing(2)
}))

/**
 * Decides whether to show ad or not
 * @param paginate Article should be paginated
 * @param pageParam Page parameter in query string
 * @param usage String containing comma separated values of usage options
 * @returns boolean indicating whether to show ad or not
 */
function showAd(paginate: boolean, pageParam: string | null, usage: string) {
    if (!usage) return true

    const usageArr = usage?.split(",") ?? []

    // Page has paging but user has selected to show all
    const isPaged = pageParam !== "all" && paginate

    if (usageArr.includes("paged") && isPaged) return true
    if (usageArr.includes("non-paged") && !isPaged) return true

    return false
}
