import { PortableTextTypeComponentProps } from "@portabletext/react"

import AdInstream, { AdInstreamType } from "Components/Ad/AdInstream"

interface IInstreamAdProps {
    adUnitType: string
    adUnitUsage: string
}

export default function InstreamAd(
    props: PortableTextTypeComponentProps<IInstreamAdProps>
) {
    const { adUnitType, adUnitUsage } = props.value

    return (
        <AdInstream type={adUnitType as AdInstreamType} usage={adUnitUsage} />
    )
}
