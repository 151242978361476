import { t } from "translations"

import Grid from "@mui/material/Grid"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"

import { IContainerCard } from "data/contentData/interfaces/mongodb/IContainerCard"

import Icon from "Components/Icon/Icon"
import PortableText from "Components/PortableText/PortableText"

export default function FooterComponent({
    footerData
}: {
    footerData: IContainerCard[]
}) {
    if (!footerData.length) return null

    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }

    return (
        <StyledFooter>
            <StyledIconButton
                size="large"
                onClick={scrollToTop}
                aria-label={t.footer.scrollTopButton}>
                <Icon name="arrowUpward" />
            </StyledIconButton>
            {footerData.map(section => (
                <StyledSectionWrapper
                    color={section.color}
                    key={section.contentId}>
                    <StyledSection
                        maxWidth="lg"
                        direction={{ xs: "column", sm: "column", md: "row" }}
                        container
                        color={section.color}>
                        {section.cards?.map(card => (
                            <StyledCardItem
                                key={card.contentId}
                                color={card.color}
                                item>
                                {card.title && (
                                    <Typography
                                        variant="h6"
                                        component="h1"
                                        color="inherit">
                                        {card.title}
                                    </Typography>
                                )}

                                <PortableText value={card.blockContent} />
                            </StyledCardItem>
                        ))}
                    </StyledSection>
                </StyledSectionWrapper>
            ))}
        </StyledFooter>
    )
}

const StyledFooter = styled("footer")(({ theme }) => ({
    position: "relative",
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: theme.palette.secondary[200]
}))

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    position: "absolute",
    top: "-24px",
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    "&:hover": {
        backgroundColor: theme.palette.primary.main
    }
}))
const StyledSectionWrapper = styled("div")<{ color: string }>(
    ({ color, theme }) => ({
        width: "100%",
        backgroundColor:
            color === "primary"
                ? theme.palette.primary.main
                : theme.palette.secondary[200]
    })
)

const StyledSection = styled(Grid)<{ color: string }>(({ color, theme }) => ({
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    width: "100%",
    maxWidth: theme.breakpoints.values.lg,
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor:
        color === "primary"
            ? theme.palette.primary.main
            : theme.palette.secondary[200],
    padding: theme.spacing(4, 2),
    gap: theme.spacing(2),

    [theme.breakpoints.up("sm")]: {
        padding: theme.spacing(4)
    },
    [theme.breakpoints.up("md")]: {
        padding: theme.spacing(6),
        gap: theme.spacing(6)
    }
}))

const StyledCardItem = styled(Grid)<{ color: string }>(({ theme, color }) => ({
    width: color === "primary" ? "100%" : "auto",
    fontSize: theme.typography.subtitle2.fontSize,
    color:
        color === "primary"
            ? theme.palette.common.white
            : theme.palette.text.primary,

    "&:has(img)": {
        marginTop: "auto",
        marginBottom: 0
    },

    "& img": {
        margin: 0,
        marginLeft: "auto",
        marginRight: "auto"
    },
    "& p": {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(0)
    },

    "& a": {
        color:
            color === "primary"
                ? theme.palette.common.white
                : theme.palette.text.primary,
        textDecoration: "none",
        "&:hover": {
            textDecoration: "underline"
        }
    }
}))
