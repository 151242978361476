import { createContext, useContext, useEffect, useState } from "react"

import { IArticle } from "data/contentData/interfaces/mongodb/IArticle"
import { IPage } from "data/contentData/interfaces/mongodb/IPage"
import { ISection } from "data/contentData/interfaces/mongodb/ISection"
import getQueryStringValue from "lib/getQueryStringValue"

interface IPagingContext {
    pageParam: string | null
    paginate: boolean
    paging: IPaging
    showHeader: boolean
    pages: IPagedSection[]
}

interface IPaging {
    prev: number
    current: number
    next: number
}

interface IPagedSection extends ISection {
    leadText?: any
    url: string
    isCurrent: boolean
}

const PagingContext = createContext<IPagingContext>({
    pageParam: null,
    paginate: false,
    paging: { prev: 1, current: 1, next: 1 },
    showHeader: true,
    pages: []
})

export function PagingProvider({
    page,
    children
}: {
    page: IPage
    children: JSX.Element | JSX.Element[]
}) {
    const article = page as IArticle
    const pageParam = getQueryStringValue("page")

    const [paginate, setPaginate] = useState(() =>
        article.paginate === undefined ? false : article.paginate
    )
    useEffect(() => {
        const _paginate =
            article.paginate === undefined ? false : article.paginate
        setPaginate(_paginate)
    }, [article.paginate])

    const [showHeader, setShowHeader] = useState(true)
    useEffect(() => {
        const validPageParams = [undefined, null, "all", "1"]
        setShowHeader(
            !paginate || (paginate && validPageParams.includes(pageParam))
        )
    }, [pageParam, paginate])

    const [paging, setPaging] = useState<IPaging>({
        prev: 1,
        current: 1,
        next: 1
    })
    useEffect(() => {
        if (!paginate) return

        const sectionCount = page.sections?.length ?? 0
        if (pageParam !== "all" && sectionCount > 0) {
            const current = pageParam ? parseInt(pageParam) : 1
            const prev = current - 1 < 1 ? 1 : current - 1
            const next = current + 1 > sectionCount ? current : current + 1

            setPaging({
                prev,
                current,
                next
            })
        }
    }, [page.sections, pageParam, paginate])

    const pages = useSectionsToPages(article, paging)

    const value: IPagingContext = {
        pageParam,
        paginate,
        paging,
        showHeader,
        pages
    }

    return (
        <PagingContext.Provider value={value}>
            {children}
        </PagingContext.Provider>
    )
}

/**
 * throws an error if the PagingContext can not be found
 * @returns error or paging context
 */

export default function usePagingContext() {
    const context = useContext(PagingContext)
    if (!context)
        throw new Error("usePagingContext must be used within a PagingProvider")

    return context
}

/**
 * Converts sections to pages with url and isCurrent
 * @param article
 * @param paging
 * @returns pages
 */
function useSectionsToPages(article: IArticle, paging: IPaging) {
    const [pages, setPages] = useState<IPagedSection[]>([])
    useEffect(() => {
        if (article.sections) {
            const leadSection = article.sections.find(p => p.type === "root")

            // Merge root section with first section if paginateLeadText === false
            const _sections = article.paginateLeadText
                ? article.sections.map(section => {
                    return section.type === "root"
                        ? { ...section, name: article.title }
                        : section
                })
                : article.sections
                    .filter(p => p.type !== "root")
                    .map((section, index) => {
                        const _section =
                            index === 0 && leadSection
                                ? {
                                    ...section,
                                    leadText: leadSection.blockContent
                                }
                                : section
                        return _section
                    })

            // Convert sections to pages with url and isCurrent
            const _pages = _sections.map((section, index) => {
                return {
                    ...section,
                    url: `${article.url}?page=${index + 1}`,
                    isCurrent: paging.current === index + 1
                }
            })

            setPages(_pages)
        }
    }, [
        article.paginateLeadText,
        article.sections,
        article.title,
        article.url,
        paging
    ])

    return pages
}
