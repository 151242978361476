import { useRouter } from "next/router"
import { useEffect, useState } from "react"

import { IArticle } from "data/contentData/interfaces/mongodb/IArticle"
import { IPage } from "data/contentData/interfaces/mongodb/IPage"
import { IPublic } from "data/contentData/interfaces/mongodb/IPublic"
import { getParentTopic } from "lib/getParentTopic"

import useUserCategory from "Components/UserCategoryModal/useUserCategory"

export interface IGamValues {
    env: "prod" | "test"
    site: string
    section: string
    context: string
    tags: string
}

export default function useGetGAMValues(page: IPage) {
    const { userCategory } = useUserCategory()
    const router = useRouter()

    const [values, setValues] = useState<IGamValues>({
        env: process.env.NODE_ENV === "production" ? "prod" : "test",
        site: "",
        section: "",
        context: "",
        tags: ""
    })

    useEffect(() => {
        if (page && page.types && userCategory) {
            const site = userCategory === "professional" ? "pro" : "public"
            const section =
                router.asPath === "/" || router.asPath === "/pro"
                    ? "Forside"
                    : getParentTopic(page as IPublic)?.name ?? ""

            const context = page.types?.slice(-1).join() ?? ""
            const tags = (page as IArticle).tags?.join(",") ?? ""

            setValues(p => ({
                ...p,
                site,
                section,
                context,
                tags
            }))
        }
    }, [page, router, userCategory])

    return values
}
