import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import Typography from "@mui/material/Typography"
import { styled, useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"

import { IMegamenuCategory } from "data/contentData/interfaces/IMegamenuCategory"

import Icon from "Components/Icon/Icon"
import Link from "Components/Link"

const icons: { [key: string]: string } = {
    LocalOfferIcon: "localOffer",
    InfoIcon: "info"
}

export default function MegamenuItem({
    item,
    handleClose
}: {
    item: IMegamenuCategory
    handleClose: () => void
}) {
    const iconName = item.icon
    const theme = useTheme()
    const isMdUp = useMediaQuery(theme.breakpoints.up("md"))
    const isTopic = item.nested === true

    return (
        <StyledWrapper>
            <StyledHeader variant="body2">
                <StyledCategoriesIcon
                    name={icons[iconName]}
                    fontSize="small"
                />
                {item.name}
            </StyledHeader>
            <StyledSectionWrapper isnested={item.nested.toString()}>
                {isMdUp
                    ? item.children.map(child => (
                          <div key={child.contentId}>
                              <StyledLink
                                  variant="h6"
                                  href={child.url}
                                  onClick={handleClose}
                                  isbold={false.toString()}>
                                  {child.name}
                                  {isTopic && (
                                      <StyledCategoriesIcon
                                          name="arrowForwardIos"
                                          fontSize="inherit"
                                      />
                                  )}
                              </StyledLink>
                              {child.children && (
                                  <List
                                      dense
                                      disablePadding>
                                      {child.children?.map(subitem => (
                                          <ListItem
                                              disableGutters
                                              key={subitem.contentId}>
                                              <StyledLink
                                                  variant="subtitle1"
                                                  href={subitem.url}
                                                  onClick={handleClose}
                                                  isbold={true.toString()}>
                                                  {subitem.name}
                                              </StyledLink>
                                          </ListItem>
                                      ))}
                                  </List>
                              )}
                          </div>
                      ))
                    : item.children.map(child => (
                          <div key={child.contentId}>
                              {isTopic && child.children ? (
                                  <StyledAccordion>
                                      <StyledAccordionSummary
                                          expandIcon={
                                              <Icon name="expandMore" />
                                          }
                                          aria-controls={`${child.name}-content`}
                                          id={`${child.name}-header`}>
                                          <StyledMobileTopicLink
                                              href={child.url}
                                              variant="h6"
                                              onClick={handleClose}
                                              isbold={false.toString()}
                                              isnested={true.toString()}>
                                              {child.name}
                                          </StyledMobileTopicLink>
                                      </StyledAccordionSummary>
                                      <StyledAccordionDetails>
                                          <List>
                                              {child.children?.map(subitem => (
                                                  <ListItem
                                                      disableGutters
                                                      key={subitem.contentId}>
                                                      <StyledMobileTopicLink
                                                          variant="h6"
                                                          href={subitem.url}
                                                          onClick={handleClose}
                                                          isbold={true.toString()}
                                                          isnested={true.toString()}>
                                                          {subitem.name}
                                                      </StyledMobileTopicLink>
                                                  </ListItem>
                                              ))}
                                          </List>
                                      </StyledAccordionDetails>
                                  </StyledAccordion>
                              ) : isTopic ? (
                                  <StyledMobileTopicLink
                                      variant="h6"
                                      href={child.url}
                                      onClick={handleClose}
                                      isbold={false.toString()}
                                      isnested={false.toString()}>
                                      {child.name}
                                  </StyledMobileTopicLink>
                              ) : (
                                  <StyledMobileLink
                                      href={child.url}
                                      variant="h6"
                                      onClick={handleClose}
                                      isbold={false.toString()}>
                                      {child.name}
                                  </StyledMobileLink>
                              )}
                          </div>
                      ))}
            </StyledSectionWrapper>
        </StyledWrapper>
    )
}

const StyledWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2)
}))

const StyledSectionWrapper = styled("div")<{ isnested: string }>(
    ({ isnested, theme }) => ({
        display: "grid",
        gap: isnested === "true" ? theme.spacing(0) : theme.spacing(2),
        [theme.breakpoints.up("sm")]: {
            gap: isnested === "true" ? theme.spacing(0) : theme.spacing(1)
        },
        [theme.breakpoints.up("md")]: {
            gap: isnested === "true" ? theme.spacing(2) : theme.spacing(1),
            gridTemplateColumns: isnested === "true" ? "1fr 1fr" : "1fr"
        }
    })
)

const StyledHeader = styled(Typography)(({ theme }) => ({
    color: theme.palette.getContrastText(theme.palette.primary.light),
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    textTransform: "uppercase"
}))

const StyledCategoriesIcon = styled(Icon)(({ theme }) => ({
    color: theme.palette.common.white
}))

const StyledLink = styled(Link)<{ isbold: string }>(({ isbold, theme }) => ({
    ...(isbold === "false" && {
        fontWeight: 600,
        padding: theme.spacing(0, 0),
        gap: theme.spacing(1)
    }),
    color: theme.palette.common.white,
    [theme.breakpoints.up("sm")]: {
        padding: theme.spacing(0, 1),
        display: "flex",
        flexGrow: 1,
        alignItems: "center"
    }
}))

const StyledMobileTopicLink = styled(Link)<{
    isbold: string
    isnested: string
}>(({ isbold, isnested, theme }) => ({
    ...(isbold === "false" && {
        fontWeight: 600,
        padding: theme.spacing(0, 0),
        gap: theme.spacing(1)
    }),
    ...(isnested === "false" && {
        padding: theme.spacing(1, 0)
    }),
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
    color: theme.palette.common.white
}))

const StyledMobileLink = styled(Link)<{ isbold: string }>(
    ({ isbold, theme }) => ({
        ...(isbold === "false" && {
            fontWeight: 600,
            padding: theme.spacing(0, 0),
            gap: theme.spacing(1)
        }),
        color: theme.palette.common.white
    })
)

const StyledAccordion = styled(Accordion)(({ theme }) => ({
    "& .MuiAccordionSummary-root": {
        backgroundColor: theme.palette.primary.main,
        padding: theme.spacing(0)
    }
}))

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    "& .MuiAccordionSummary-content": {
        margin: theme.spacing(0)
    },
    "& .MuiAccordionSummary-expandIconWrapper": {
        color: theme.palette.common.white,
        padding: theme.spacing(0, 3)
    }
}))

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
    backgroundColor: theme.palette.primary.light
}))
